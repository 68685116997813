import NextLink, { LinkProps as NextLinkProps } from 'next/link'
import { ReactNode } from 'react'
import { UrlObject } from 'url'
type Url = string | UrlObject

interface Props extends NextLinkProps {
  children: ReactNode
}

type ConditionalLinkProps = Props | { href?: Url; children: ReactNode }
const ConditionalLink: React.FC<ConditionalLinkProps> = ({ href, children, ...props }) => {
  if (!href) return <>{children}</>
  return (
    <NextLink href={href} {...props}>
      {children}
    </NextLink>
  );
}

export default ConditionalLink
