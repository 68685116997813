import React, { useEffect, useRef, useState } from 'react'

interface HorizontalScrollBarProps {
  /**
   * Ref to the scrolling content (parent container).
   * Must have overflow-x: scroll or auto.
   */
  scrollContentRef: React.RefObject<HTMLDivElement>
  height?: string
  borderRadius?: string
  background?: string
  marginTop?: string
  thumbBackground?: string
  thumbBorderRadius?: string
  maxThumbSize?: number
}

/**
 * Custom horizontal scrollbar that supports desktop (mouse)
 * and mobile (touch) dragging.
 */
const HorizontalScrollBar: React.FC<HorizontalScrollBarProps> = ({
  scrollContentRef,
  height,
  borderRadius,
  background,
  marginTop,
  thumbBackground,
  thumbBorderRadius,
  maxThumbSize,
}) => {
  const MIN_THUMB_SIZE = 20 // Default min thumb size
  const MAX_THUMB_SIZE = maxThumbSize ?? 120 // Default max thumb size
  const trackRef = useRef<HTMLDivElement>(null)

  const [thumbWidth, setThumbWidth] = useState(0)
  const [thumbLeft, setThumbLeft] = useState(0)

  // For dragging
  const [isDragging, setIsDragging] = useState(false)
  const dragStartXRef = useRef(0) // Mouse or touch X position at drag start
  const initialThumbLeftRef = useRef(0) // Thumb left offset at drag start

  /**
   * Updates the thumb size and position based on the scroll container.
   */
  const updateThumbPosition = () => {
    if (!scrollContentRef.current || !trackRef.current) return

    const { scrollWidth, clientWidth, scrollLeft } = scrollContentRef.current
    const trackWidth = trackRef.current.clientWidth

    // If there's no overflow, hide the thumb
    if (scrollWidth <= clientWidth) {
      setThumbWidth(0)
      setThumbLeft(0)
      return
    }

    // Ratio of how far we've scrolled
    const scrollRatio = scrollLeft / (scrollWidth - clientWidth)

    const rawThumbWidth = trackWidth * (clientWidth / scrollWidth)
    // Calculate dynamic width for the thumb
    const dynamicThumbWidth = Math.min(MAX_THUMB_SIZE, Math.max(MIN_THUMB_SIZE, rawThumbWidth))

    // Calculate the left offset for the thumb
    const maxThumbLeft = trackWidth - dynamicThumbWidth
    const newThumbLeft = scrollRatio * maxThumbLeft

    setThumbWidth(dynamicThumbWidth)
    setThumbLeft(newThumbLeft)
  }

  /**
   * Convert a thumbLeft value into a scrollLeft for the content.
   */
  const thumbLeftToScrollLeft = (
    newThumbLeft: number,
    trackWidth: number,
    scrollWidth: number,
    clientWidth: number
  ) => {
    const maxThumbLeft = trackWidth - thumbWidth
    const scrollRange = scrollWidth - clientWidth
    const scrollRatio = newThumbLeft / maxThumbLeft
    return scrollRatio * scrollRange
  }

  /**
   * Called on mouse or touch start over the thumb.
   */
  const startDrag = (startClientX: number) => {
    setIsDragging(true)
    dragStartXRef.current = startClientX
    initialThumbLeftRef.current = thumbLeft
  }

  /**
   * Mouse down handler.
   */
  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    startDrag(e.clientX)
  }

  /**
   * Touch start handler.
   */
  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    // Use the first touch as reference
    const touch = e.touches[0]
    startDrag(touch.clientX)
  }

  /**
   * Drag move (mouse or touch).
   */
  const doDrag = (clientX: number) => {
    if (!scrollContentRef.current || !trackRef.current) return

    const trackWidth = trackRef.current.clientWidth
    const { scrollWidth, clientWidth } = scrollContentRef.current

    // How many pixels the thumb can move in the track
    const maxThumbLeft = trackWidth - thumbWidth

    // Calculate how far the mouse/touch has moved since drag start
    const deltaX = clientX - dragStartXRef.current
    // Calculate the new thumb position
    const newThumbLeft = Math.min(Math.max(initialThumbLeftRef.current + deltaX, 0), maxThumbLeft)

    // Convert newThumbLeft to a scrollLeft
    const newScrollLeft = thumbLeftToScrollLeft(newThumbLeft, trackWidth, scrollWidth, clientWidth)

    // Update the UI
    setThumbLeft(newThumbLeft)
    scrollContentRef.current.scrollLeft = newScrollLeft
  }

  /**
   * Mouse move handler.
   */
  const onMouseMove = (e: MouseEvent) => {
    e.preventDefault()
    doDrag(e.clientX)
  }

  /**
   * Touch move handler.
   */
  const onTouchMove = (e: TouchEvent) => {
    // If multiple touches, ignore or handle differently
    if (e.touches.length > 1) return
    const touch = e.touches[0]
    doDrag(touch.clientX)
  }

  /**
   * Ends dragging on mouse up or touch end.
   */
  const endDrag = () => {
    setIsDragging(false)
  }

  /**
   * Handle clicks on the track to jump to the clicked position.
   */
  const handleTrackClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!scrollContentRef.current || !trackRef.current) return

    const trackRect = trackRef.current.getBoundingClientRect()
    const clickX = e.clientX - trackRect.left

    const trackWidth = trackRef.current.clientWidth
    const { scrollWidth, clientWidth } = scrollContentRef.current

    const clickRatio = clickX / trackWidth
    const newScrollLeft = clickRatio * (scrollWidth - clientWidth)

    // Smooth scroll to the new position
    scrollContentRef.current.scrollTo({
      left: newScrollLeft,
      behavior: 'instant',
    })
  }

  /**
   * Setup event listeners for scroll & resize updates.
   */
  useEffect(() => {
    const contentEl = scrollContentRef.current
    // Recalc thumb on scroll and resize
    if (contentEl) {
      contentEl.addEventListener('scroll', updateThumbPosition)
      window.addEventListener('resize', updateThumbPosition)
      // Initial calculation
      updateThumbPosition()
    }

    // Cleanup
    return () => {
      contentEl?.removeEventListener('scroll', updateThumbPosition)
      window.removeEventListener('resize', updateThumbPosition)
    }
  }, [scrollContentRef])

  /**
   * Setup global mouse & touch move/up events while dragging.
   */
  useEffect(() => {
    if (isDragging) {
      // Mouse
      document.addEventListener('mousemove', onMouseMove, { passive: false })
      document.addEventListener('mouseup', endDrag, { passive: false })
      // Touch
      document.addEventListener('touchmove', onTouchMove, { passive: false })
      document.addEventListener('touchend', endDrag, { passive: false })
    } else {
      // Cleanup
      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('mouseup', endDrag)
      document.removeEventListener('touchmove', onTouchMove)
      document.removeEventListener('touchend', endDrag)
    }

    // Cleanup on unmount or on isDragging toggling
    return () => {
      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('mouseup', endDrag)
      document.removeEventListener('touchmove', onTouchMove)
      document.removeEventListener('touchend', endDrag)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging])

  return (
    <div
      ref={trackRef}
      onClick={handleTrackClick} // Add onClick handler to the track
      style={{
        position: 'relative',
        background: `${background ?? '#ccc'}`,
        height: `${height ?? '8px'}`,
        borderRadius: `${borderRadius ?? '4px'}`,
        marginTop: `${marginTop ?? '8px'}`,
        userSelect: 'none', // avoid text selection while dragging
        touchAction: 'none', // important for iOS Safari, disables default gestures
        cursor: 'pointer', // Change cursor to indicate interactivity
      }}
    >
      {/* Only render the thumb if there's overflow */}
      {thumbWidth > 0 && (
        <div
          onMouseDown={handleMouseDown}
          onTouchStart={handleTouchStart}
          onClick={(e) => e.stopPropagation()} // Prevent track click when thumb is clicked
          style={{
            position: 'absolute',
            left: `${thumbLeft}px`,
            width: `${thumbWidth}px`,
            height: '100%',
            background: `${thumbBackground ?? '#999'}`,
            borderRadius: `${thumbBorderRadius ?? '4px'}`,
            cursor: 'pointer',
            transition: isDragging ? 'none' : 'left 0.1s',
          }}
        />
      )}
    </div>
  )
}

export default HorizontalScrollBar
