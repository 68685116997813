import HorizontalScrollBar from '@components/common/HorizontalScrollBar/HorizontalScrollBar'
import { Button, ConditionalLink, WYSIWYG } from '@components/ui'
import { PrismicImage, PrismicMedia } from '@lib/prismic'
import classNames from 'classnames'
import { useKeenSlider } from 'keen-slider/react'
import Image from 'next/legacy/image'
import { RichTextBlock } from 'prismic-reactjs'
import { isEmpty } from 'ramda'
import { FC, useEffect, useRef } from 'react'
import { useWindowSize } from 'usehooks-ts'

type EmptyObject = { [K in any]: never }

interface MultiPanelTile {
  icon: PrismicImage | EmptyObject
  image_1: PrismicImage | EmptyObject
  image_2: PrismicImage | EmptyObject
  image_3: PrismicImage | EmptyObject
  image_mobile_1: PrismicImage | EmptyObject
  image_mobile_2: PrismicImage | EmptyObject
  image_mobile_3: PrismicImage | EmptyObject
  video_1: PrismicMedia
  video_2: PrismicMedia
  video_3: PrismicMedia
  video_mobile_1: PrismicMedia
  video_mobile_2: PrismicMedia
  video_mobile_3: PrismicMedia
  heading: string | null
  subheading: string | null
  description: RichTextBlock[]
  button_text: string | null
  button_style: 'primary' | 'secondary'
  button_link: string | null
}

interface MultiPanelProps {
  primary: {
    id: string
    number_of_tiles: '2' | '3'
    preheading: RichTextBlock[]
    heading: RichTextBlock[]
    padding_top_desktop: string | null
    padding_bottom_desktop: string | null
    padding_top_mobile: string | null
    padding_bottom_mobile: string | null
    background_colour: string | null
    text_colour: 'dark' | 'light' | null
  }
  items: MultiPanelTile[]
}

const MultiPanel: FC<MultiPanelProps> = ({ primary, items }) => {
  const scrollContainerRef = useRef() as React.MutableRefObject<HTMLDivElement>
  const id = primary.id
  const paddingTopDesktop = primary.padding_top_desktop || '72px'
  const paddingTopMobile = primary.padding_top_mobile || '40px'
  const paddingBottomDesktop = primary.padding_bottom_desktop || '80px'
  const paddingBottomMobile = primary.padding_bottom_mobile || '0px'
  const bgColour = primary.background_colour || 'none'

  const emptyPreheading = !primary.preheading?.[0]?.text
  const emptyHeading = !primary.heading?.[0]?.text
  const numberOfItems = items.length

  return (
    <div id={id} className="slice">
      <div className="w-full desktop:max-w-desktop laptop:px-32 mx-auto">
        <div className="flex flex-col gap-32 laptop:gap-40">
          {(!emptyPreheading || !emptyHeading) && (
            <div className="flex flex-col gap-8">
              {!emptyPreheading && (
                <h5
                  className={classNames('title text-center px-20 laptop:px-0', {
                    'text-moss': primary.text_colour === 'dark',
                    'text-white': primary.text_colour === 'light',
                  })}
                >
                  <WYSIWYG content={primary.preheading} resetStyling={false} />
                </h5>
              )}
              {!emptyHeading && (
                <div
                  className={classNames('heading-5 text-center px-20 laptop:px-0', {
                    'text-black': primary.text_colour === 'dark',
                    'text-white': primary.text_colour === 'light',
                  })}
                >
                  <WYSIWYG content={primary.heading} resetStyling={false} />
                </div>
              )}
            </div>
          )}
          {numberOfItems <= 3 ? (
            <div className="flex flex-col laptop:flex-row gap-16">
              {items.map((item) => (
                <MultiPanelItem
                  key={JSON.stringify(item)}
                  item={item}
                  numberOfTiles={Number(primary.number_of_tiles)}
                />
              ))}
            </div>
          ) : (
            <div>
              <div ref={scrollContainerRef} className="flex flex-row gap-16 overflow-x-scroll scrollbar-hide">
                {items.map((item) => {
                  const firstItem = items?.[0]
                  const firstImageRatio = firstItem?.image_1?.dimensions?.width / firstItem?.image_1?.dimensions?.height
                  const firstMobileImageRatio =
                    firstItem?.image_mobile_1?.dimensions?.width / firstItem?.image_mobile_1?.dimensions?.height
                  const defaultRatio = firstImageRatio || firstMobileImageRatio || 9 / 16
                  return (
                    <div className="flex-1 scrollItem" key={JSON.stringify(item)}>
                      <MultiPanelItem item={item} numberOfTiles={numberOfItems} defaultRatio={defaultRatio} />
                    </div>
                  )
                })}
              </div>
              <div className="laptop:mt-16 mt-8">
                <HorizontalScrollBar
                  scrollContentRef={scrollContainerRef}
                  thumbBackground="#000000"
                  thumbBorderRadius="9999px"
                  borderRadius="9999px"
                  background="#f1f1f1"
                  marginTop="0px"
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <style jsx>
        {`
          .slice {
            background-color: ${bgColour};
            padding-top: ${paddingTopMobile};
            padding-bottom: ${paddingBottomMobile};
          }
          .scrollItem {
            min-width: calc((100% - 16px) / 1.4);
          }
          @media (min-width: 1024px) {
            .slice {
              padding-top: ${paddingTopDesktop} !important;
              padding-bottom: ${paddingBottomDesktop} !important;
            }
            .scrollItem {
              min-width: calc((100% - 112px) / 4 * 1.2667);
            }
          }
        `}
      </style>
    </div>
  )
}

interface MultiPanelItemProps {
  item: MultiPanelTile
  numberOfTiles: number
  defaultRatio?: number
}
const MultiPanelItem: FC<MultiPanelItemProps> = ({ item, numberOfTiles, defaultRatio }) => {
  const { width } = useWindowSize()
  const isMobile = width < 1024
  const ratio = numberOfTiles > 3 ? defaultRatio ?? 9 / 16 : numberOfTiles === 2 ? (isMobile ? 4 / 6 : 4 / 5) : 4 / 6
  const extractAssets = (item: MultiPanelTile, isMobile: boolean = false) => {
    const assets = []
    const images = isMobile
      ? [item.image_mobile_1, item.image_mobile_2, item.image_mobile_3]
      : [item.image_1, item.image_2, item.image_3]
    const videos = isMobile
      ? [item.video_mobile_1, item.video_mobile_2, item.video_mobile_3]
      : [item.video_1, item.video_2, item.video_3]
    for (let i = 0; i < 3; i++) {
      const asset = videos[i]?.url ? videos[i] : images[i]
      assets.push(asset)
    }
    return assets.filter((asset) => !!asset.url)
  }
  const mobileAssets = extractAssets(item, true)
  const desktopAssets = extractAssets(item, false)

  const assets = isMobile && mobileAssets.length > 0 ? mobileAssets : desktopAssets

  const icon = !isEmpty(item.icon) ? (item.icon as PrismicImage) : undefined

  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    loop: true,
    slidesPerView: 1,
    controls: true,
  })

  useEffect(() => {
    if (slider) {
      slider.resize()
    }
  }, [slider])

  const emptyDescription = !item.description?.[0]?.text
  return (
    <div className="flex-1 relative">
      <ConditionalLink href={item.button_link || undefined} passHref>
        <>
          <div ref={sliderRef} className="keen-slider">
            {assets.map((asset, index) => (
              <div key={`${asset.url}-${index}`} className="keen-slider__slide">
                <div
                  className={classNames('aspect-w-4', {
                    'laptop:aspect-h-5 aspect-h-6': numberOfTiles === 2,
                    'aspect-h-6': numberOfTiles === 3,
                  })}
                >
                  {'dimensions' in asset && (
                    <div>
                      <Image
                        src={asset.url}
                        alt={asset.alt}
                        objectFit="cover"
                        layout="responsive"
                        width={500 * ratio}
                        height={500}
                      />
                    </div>
                  )}
                  {'link_type' in asset && (
                    <video loop={true} muted autoPlay={true} playsInline={true} className="h-full w-full object-cover">
                      <source src={asset.url} type="video/mp4" />
                    </video>
                  )}
                </div>
              </div>
            ))}
          </div>
          {assets.length > 1 && (
            <div className="absolute top-0 right-0 flex flex-row gap-8 z-10">
              <button
                onClick={(e) => {
                  e.preventDefault()
                  slider.prev()
                }}
                className="p-20"
              >
                <Image src="/icons/caret-left-white.svg" width="7" height="14" alt="" />
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  slider.next()
                }}
                className="p-20"
              >
                <Image src="/icons/caret-right-white.svg" width="7" height="14" alt="" />
              </button>
            </div>
          )}
          <div
            className={classNames(
              'absolute bottom-0 left-0 right-0 pl-20 py-40 laptop:pl-40 laptop:py-40 flex flex-col gap-24',
              {
                'pr-40 laptop:pr-100': numberOfTiles === 2,
                'pr-40': numberOfTiles === 3,
                'h-full w-full flex flex-col justify-end': numberOfTiles > 3,
              }
            )}
          >
            {(!!item.heading || !!item.subheading || !emptyDescription) && (
              <div className="flex flex-col gap-8 text-white">
                {!!item.heading && (
                  <div
                    className={classNames(
                      `${isMobile && numberOfTiles > 3 ? 'text-20 leading-32' : 'text-24 leading-36'} `
                    )}
                  >
                    {item.heading}
                  </div>
                )}
                {!!item.subheading && (
                  <div className="flex flex-row items-center gap-8">
                    {icon && (
                      <div className="w-18 h-18 relative flex-shrink-0">
                        <Image src={icon.url} alt={icon.alt} layout="fill" objectFit="cover" />
                      </div>
                    )}
                    <span
                      className={classNames(
                        `font-g-medium ${isMobile && numberOfTiles > 3 ? 'text-14 leading-16' : ' text-16 leading-18'} `
                      )}
                    >
                      {item.subheading}
                    </span>
                  </div>
                )}
                {!emptyDescription && (
                  <div
                    className={classNames(
                      `font-g-medium ${isMobile && numberOfTiles > 3 ? 'text-12 leading-14' : 'text-14 leading-24'} `
                    )}
                  >
                    <WYSIWYG content={item.description} resetStyling={false} />
                  </div>
                )}
              </div>
            )}
            {item.button_link && item.button_text && (
              <ConditionalLink href={item.button_link || undefined}>
                <Button
                  size={isMobile && numberOfTiles > 3 ? 'small' : 'regular'}
                  variant={item.button_style}
                  bg={item.button_style === 'secondary' ? 'dark' : 'light'}
                >
                  {item.button_text}
                </Button>
              </ConditionalLink>
            )}
          </div>
        </>
      </ConditionalLink>
    </div>
  )
}

export default MultiPanel
